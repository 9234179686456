import * as React from 'react';
import hotkeys from 'hotkeys-js';
import NewBatchMenu from './NewBatchMenu';
import BatchesTableProgressCell from './BatchesTableProgressCell';
import BatchesTableDownloadCell from './BatchesTableDownloadCell';
import BatchesTableDeleteCell from './BatchesTableDeleteCell';
import BatchesTableNameCell from './BatchesTableNameCell';
import BatchesTableTotalRowsCell from './BatchesTableTotalRowsCell';
import BatchesTableDateCell from './BatchesTableDateCell';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo } from '@fortawesome/free-solid-svg-icons/faRedo';
import {
    Section,
    Button,
    DataTable,
    ImposiumDropdown,
    ShortcutMenu
} from '@imposium-hub/components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    getBatches,
    selectBatch,
    filterBatchesBy,
    multiSelectBatches
} from '../../redux/actions/batches';
import { addTag, addTags } from '../../redux/actions/tags';
import { BATCHES_COPY } from '../../constants/copy';
import { ExportToCsv } from 'export-to-csv';
import { setPublishWizard } from '../../redux/actions/experiences';
import { setActiveTab } from '../../redux/actions/active-tab';
import { browserHistory } from 'react-router';
import BatchesTableModerationStatus from './BatchesTableModerationStatus';
import BatchesTableRenderCell from './BatchesTableRenderCell';
import _ from 'lodash';
import BatchesTableCRMImportsCell from './BatchesTableCRMImportsCell';

interface IBatchesTableProps {
    story: any;
    batchesList: any;
    experiencesList: any;
    columnMasks: any[];
    getBatches: () => any;
    selectBatch: (batch: any) => any;
    multiSelectBatches: (batches: any) => any;
    filterBatchesBy: (filters: any) => any;
    addTag: (tag: any) => any;
    addTags: (tags: any) => any;
    selected: any;
    setPublishWizard: (e: boolean) => any;
    batchId: string;
    setActiveTab: (tabName: string) => any;
}

interface IBatchesTableState {
    showNewMenu: boolean;
    showShortcutsMenu: boolean;
    multiSelected: any;
}

class BatchesTable extends React.PureComponent<IBatchesTableProps, IBatchesTableState> {
    private static readonly CSV_EXPORT_CONFIG: any = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: false,
        showTitle: false,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true
    };

    private static readonly SELECTED_BTN_COL_WIDTH: number = 26;

    private readonly DEFAULT_COLUMNS: any = {
        date_created: {
            Header: BATCHES_COPY.table.createdHeader,
            Cell: (cell: any) => <BatchesTableDateCell cell={cell} />,
            resizable: true,
            width: 130
        },
        name: {
            Header: BATCHES_COPY.table.nameHeader,
            Cell: (c) => <BatchesTableNameCell cell={c} />,
            resizable: false
        },
        status: {
            Header: BATCHES_COPY.table.progressHeader,
            Cell: (c) => <BatchesTableProgressCell cell={c} />,
            resizable: false
        },
        total_rows: {
            Header: BATCHES_COPY.table.rowsHeader,
            Cell: (c) => <BatchesTableTotalRowsCell cell={c} />,
            resizable: false
        },
        total_imported_to_crm: {
            Header: BATCHES_COPY.table.crmImports,
            Cell: (c) => <BatchesTableCRMImportsCell cell={c} />,
            resizable: false
        },
        moderation_status: {
            Header: BATCHES_COPY.table.moderationStatus,
            Cell: (c) => <BatchesTableModerationStatus cell={c} />,
            resizable: false
        },
        render: {
            minWidth: BatchesTable.SELECTED_BTN_COL_WIDTH,
            width: BatchesTable.SELECTED_BTN_COL_WIDTH,
            maxWidth: BatchesTable.SELECTED_BTN_COL_WIDTH,
            resizable: false,
            disableSortBy: true,
            Header: '',
            Cell: (c) => <BatchesTableRenderCell cell={c} />
        },
        export: {
            minWidth: BatchesTable.SELECTED_BTN_COL_WIDTH,
            width: BatchesTable.SELECTED_BTN_COL_WIDTH,
            maxWidth: BatchesTable.SELECTED_BTN_COL_WIDTH,
            resizable: false,
            disableSortBy: true,
            Header: '',
            Cell: (c) => <BatchesTableDownloadCell cell={c} />
        },
        delete: {
            minWidth: BatchesTable.SELECTED_BTN_COL_WIDTH,
            width: BatchesTable.SELECTED_BTN_COL_WIDTH,
            maxWidth: BatchesTable.SELECTED_BTN_COL_WIDTH,
            resizable: false,
            disableSortBy: true,
            Header: '',
            Cell: (c) => <BatchesTableDeleteCell cell={c} />
        }
    };

    private readonly newMenuToggleRef: any;

    private readonly shortcutsToggleRef: any;

    private evtHandlers: any;

    constructor(p: IBatchesTableProps) {
        super(p);

        this.state = {
            showNewMenu: false,
            showShortcutsMenu: false,
            multiSelected: null
        };

        this.newMenuToggleRef = React.createRef();
        this.shortcutsToggleRef = React.createRef();
    }

    public componentDidMount = (): void => {
        if (Object.keys(this.props.story.data).length > 0) {
            this.props.getBatches();
        }

        hotkeys('shift+up', () => this.doSelectBatchOnKeyPress('up'));
        hotkeys('shift+down', () => this.doSelectBatchOnKeyPress('down'));
        hotkeys('shift+left', () => this.doPageOnKeyPress('prev'));
        hotkeys('shift+right', () => this.doPageOnKeyPress('next'));
        hotkeys('alt+shift+r', () => this.props.getBatches());
    };

    public componentDidUpdate = (prevProps: IBatchesTableProps): void => {
        const {
            batchId,
            batchesList: { data, filters, selected },
            story,
            story: {
                data: { id: storyId }
            }
        } = this.props;

        if (batchId && data.batches && Object.keys(selected).length === 0) {
            let preSelected;
            const multiPreSelected = [];
            const batchIdArray = batchId.split(',');

            if (batchIdArray.length === 1) {
                preSelected = data.batches.find((batch) => batch.id === batchIdArray[0]);
            } else if (batchIdArray.length > 1) {
                for (const id of batchIdArray) {
                    if (id) {
                        multiPreSelected.push(data.batches.find((batch) => batch.id === id));
                    }
                }

                // remove undefined, null, or empty from array
                const multiSelected = multiPreSelected.filter((n) => n);

                if (!_.isEqual(multiSelected, this.state.multiSelected)) {
                    this.setState({ multiSelected }, () =>
                        this.multiPreSelectHander(multiSelected)
                    );
                }
            }

            if (preSelected) {
                this.selectBatchHandler(preSelected);
            }
        }

        if (story.data !== prevProps.story.data || filters !== prevProps.batchesList.filters) {
            this.props.getBatches();
        }

        if (selected.hasOwnProperty('id') && selected.id !== prevProps.batchesList.selected.id) {
            const orgId = new URLSearchParams(window.location.search).get('organization_id');
            browserHistory.replace(`/${storyId}?organization_id=${orgId}&batch_id=${selected.id}`);
            this.doFilterByBatch(selected.id, selected.name);
        }
    };

    private multiPreSelectHander(array) {
        if (array.length > 1) {
            this.mutliSelectHandler(array);
        } else {
            this.selectBatchHandler(array[0]);
        }
    }

    private mutliSelectHandler(selectBatches, getIds = false) {
        const tags = [];
        const ids = [];
        for (const batch of selectBatches) {
            if (batch) {
                const tag = {
                    id: `batch:${batch.id}`,
                    name: `Batch: ${batch.name}`
                };
                tags.push(tag);
                ids.push(batch.id);
            }
        }
        this.props.addTags(tags);
        this.props.multiSelectBatches(selectBatches);

        if (getIds) {
            return ids;
        }
    }

    private selectBatchHandler(select) {
        if (select) {
            this.props.selectBatch(select);
            this.doFilterByBatch(select.id, select.name);
        }
    }

    private doSelectBatchOnKeyPress = (direction: 'up' | 'down'): void => {
        const {
            batchesList: {
                data: { batches },
                selected
            }
        } = this.props;

        if (!Array.isArray(batches)) {
            return;
        }

        const selectedIndex = batches.indexOf(batches.find((e: any) => e.id === selected.id));
        const prevIdx = selectedIndex - 1;
        const nextIdx = selectedIndex + 1;

        if (
            direction === 'up' &&
            Array.isArray(batches) &&
            prevIdx >= 0 &&
            prevIdx <= batches.length - 1
        ) {
            this.props.selectBatch(batches[prevIdx]);
        }

        if (
            direction === 'down' &&
            Array.isArray(batches) &&
            nextIdx >= 0 &&
            nextIdx <= batches.length - 1
        ) {
            this.props.selectBatch(batches[nextIdx]);
        }
    };

    private doPageOnKeyPress = (direction: 'prev' | 'next'): void => {
        const {
            batchesList: {
                data: { total_pages: totalPages },
                filters: { page }
            }
        } = this.props;
        const prevPage: number = page - 1;
        const nextPage: number = page + 1;

        if (totalPages === 1) {
            return;
        }

        if (direction === 'prev' && prevPage >= 1) {
            this.props.filterBatchesBy({ page: prevPage });
        }

        if (direction === 'next' && nextPage <= totalPages) {
            this.props.filterBatchesBy({ page: nextPage });
        }
    };

    private inventory2DefaultTemplate = (): void => {
        const storyName = this.props.story.data.name;
        // Map inventory ids to an object with empty string values, this represents a fresh row to ExportToCsv
        const emptyRow: any = this.props.columnMasks
            .map((m: any) => m.id)
            .reduce((prevState: any, currId: string) => ({ ...prevState, [currId]: '' }), {});

        const exporter = new ExportToCsv({
            ...BatchesTable.CSV_EXPORT_CONFIG,
            filename: `${storyName}-batch-template.csv`
        });

        exporter.generateCsv([emptyRow]);
    };

    private selectBatch = (row: any, shift: boolean): void => {
        const {
            batchesList: { selected, multiSelected },
            story: {
                data: { id: storyId }
            }
        } = this.props;
        if (row && row.original) {
            if (shift) {
                const selectBatches = [...multiSelected];

                if (selected && selectBatches.length === 0) {
                    selectBatches.push(selected);
                }
                selectBatches.push(row.original);
                const ids = this.mutliSelectHandler(selectBatches, true);
                const orgId = new URLSearchParams(window.location.search).get('organization_id');
                const batchIds = ids.join(',');
                browserHistory.replace(`/${storyId}?organization_id=${orgId}&batch_id=${batchIds}`);
            } else {
                this.props.selectBatch(row.original);
            }
        }
    };

    private doFilterByBatch = (id: string, name: string): void => {
        this.props.addTag({
            id: `batch:${id}`,
            name: `Batch: ${name}`
        });
    };

    private onSort = (sortState: any[]): void => {
        if (typeof sortState[0] === 'object') {
            this.props.filterBatchesBy({
                order: sortState[0].id,
                order_direction: sortState[0].desc ? 'desc' : 'asc'
            });
        }
    };

    private onPage = (pageIndex: number, pageSize: number): void => {
        const {
            batchesList: {
                filters: { page, items_per_page }
            }
        } = this.props;

        const updatedFilters: any = {};

        if (pageIndex !== page) {
            updatedFilters.page = pageIndex;
        }
        if (pageSize !== items_per_page) {
            updatedFilters.items_per_page = items_per_page;
        }

        if (Object.keys(updatedFilters).length) {
            this.props.filterBatchesBy(updatedFilters);
        }
    };

    private itemsPerPageHandler(items) {
        this.props.filterBatchesBy({ items_per_page: items });
    }

    public render() {
        const {
            experiencesList: {
                filters: { batch_id }
            },
            batchesList: {
                loading,
                selected,
                data: { batches, total_pages, batch_count: batchCount },
                filters: {
                    page,
                    order,
                    order_direction: orderDirection,
                    items_per_page: itemsPerPage
                }
            }
        } = this.props;

        const sortBy =
            order && orderDirection ? [{ id: order, desc: orderDirection === 'desc' }] : undefined;

        const selectedIds = batch_id.split(',').length > 1 ? batch_id.split(',') : selected.id;

        const btnRefresh = (
            <Button
                key='refresh'
                style='subtle'
                size='small'
                tooltip={BATCHES_COPY.tooltips.tableRefresh}
                onClick={this.props.getBatches}>
                <FontAwesomeIcon icon={faRedo} />
            </Button>
        );

        const sectionButtons: JSX.Element[] = [
            <Button
                key='publish-btn'
                color='primary'
                style='bold'
                size='small'
                tooltip={BATCHES_COPY.table.publish}
                onClick={() => this.props.setPublishWizard(true)}>
                {BATCHES_COPY.table.publish}
            </Button>,
            btnRefresh
        ];

        const totalPages = total_pages === 0 ? 1 : total_pages;

        return (
            <Section
                className='batch-section'
                title={BATCHES_COPY.sectionTitle}
                buttons={sectionButtons}>
                <DataTable
                    disableMultisort
                    disableKeyScroll
                    data={batches}
                    showInterstitial={loading}
                    defaultPageSize={itemsPerPage}
                    columns={this.DEFAULT_COLUMNS}
                    currentPage={Number(page)}
                    totalPages={totalPages}
                    itemsPerPage={itemsPerPage}
                    onItemsPerPage={(i) => this.itemsPerPageHandler(i)}
                    totalItems={batchCount}
                    onPage={this.onPage}
                    sortBy={sortBy}
                    onSort={this.onSort}
                    highlightBy={{ key: 'id', value: selectedIds }}
                    onRowClick={this.selectBatch}
                />

                <ImposiumDropdown
                    position='bottomright'
                    show={this.state.showNewMenu}
                    toggleRef={this.newMenuToggleRef}
                    onOutsideClick={() => this.setState({ showNewMenu: false })}>
                    <NewBatchMenu close={() => this.setState({ showNewMenu: false })} />
                </ImposiumDropdown>

                <ImposiumDropdown
                    position='bottomright'
                    show={this.state.showShortcutsMenu}
                    toggleRef={this.shortcutsToggleRef}
                    onOutsideClick={() => this.setState({ showShortcutsMenu: false })}>
                    <ShortcutMenu shortcutCopyMap={BATCHES_COPY.shortcuts} />
                </ImposiumDropdown>
            </Section>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            addTag,
            addTags,
            getBatches,
            selectBatch,
            multiSelectBatches,
            filterBatchesBy,
            setPublishWizard,
            setActiveTab
        },
        dispatch
    );
};

const mapStateToProps = (state): any => {
    return {
        story: state.story,
        batchesList: state.batchesList,
        columnMasks: state.columnMasks,
        selected: state.batchesList.selected,
        experiencesList: state.experiencesList
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BatchesTable);
